$ikpcolor:                        #09856E;
$ikscolor:                        #DBB11E;

$colors: (
  ikpcolor:                       $ikpcolor,
  ikscolor:                      $ikscolor
);

$theme-colors: (
  primary:                        $ikpcolor,
  secondary:                      $ikscolor,
  approved:                       #4bb21f,
  pending:                        #ffb01d,
  archived:                       #9b9b9b,
  closed:                         #09856E,
  rejected:                       #b71727,
  on_probation:                   #ffb01d,
  resigned:                       #9b9b9b,
  permanent:                      #376eb7,
  terminated:                     #b71727,
  inai:                           #f7fffd,
  done:                           #376eb7
);


// Sidebar
$sidebar-width: 250px;
$navbar-brand-width: 205px;

// Breadcrumbs
$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 1.5rem;

$input-line-height: 1.5;
$input-line-height-sm: 1.5;
$input-line-height-lg: 1.5;
$btn-line-height: 1.5;
$btn-line-height-sm: 1.5;
$btn-line-height-lg: 1.5;

$custom-select-padding-y: .375rem;
$custom-select-padding-x: .75rem;
